import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AddBlockPage } from './add-block';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
    ],
    declarations: [AddBlockPage],
    entryComponents: [
        AddBlockPage
    ]
})
export class AddBlockPageModule {}
