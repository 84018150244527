import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'edit-block-time',
  templateUrl: './edit-block-time.page.html',
  styleUrls: ['./edit-block-time.page.scss'],
})
export class EditBlockTimePage implements OnInit {

  @Input() id: string;
  @Input() part: string;
  @Input() block: any;

  @Input() part1: any;
  @Input() part2: any;
  @Input() part3: any;
  @Input() part4: any;
  @Input() part5: any;
  @Input() part6: any;
  @Input() part7: any;

  addForm: FormGroup;

  // Form state
  public error = false;

  public categories: any;

  private foundIndex: any;

  constructor(
      private fb: FormBuilder,
      public modalCtrl: ModalController,
      public toastController: ToastController,
      public loadingController: LoadingController,
      public apiService: ApiService
  ) {}

  ngOnInit(): void {

    this.addForm = this.fb.group({
      durationMinutes: [this.block.durationMinutes, [
        Validators.required
      ]],
      durationSeconds: [this.block.durationSeconds, [
        Validators.required
      ]],
    });

  }

  // Speichern des Bearbeiten blocks in der DB
  // TODO Kann vereinfacht werden, in API funktion integrieren die Anhand der ID nur den Block updated

  submitHandler(part, id): void {
    this.presentLoading().then();

    let min = this.addForm.value.durationMinutes;
    if (min.length === 1) {
      min = `0${this.addForm.value.durationMinutes}`;
    } else if (min.length < 1) {
      min = '00';
    }

    let sec = this.addForm.value.durationSeconds;
    if (sec.length === 1) {
      sec = `0${this.addForm.value.durationSeconds}`;
    } else if (sec.length < 1) {
      sec = '00';
    }

    const planObj = {
      id: this.block.id,
      tourbook: parseInt(id, 10),
      cat: this.block.cat,
      row: this.block.row,
      content: this.block.content,
      duration: `00:${min}:${sec}`,
      durationMinutes: min,
      durationSeconds: sec,
      part_id: part,
      remarks: this.block.remarks,
      sort: this.block['sort'],
      active: this.block.active,
      finished: this.block.finished,
      done: this.block.done,
    };

    let timeline = [];

    switch (part.toString()) {
      case "1":
        console.log("part 1");
        console.log(this.part1);
        // let obj = this.part1.find(obj => obj.id === planObj.id);
        this.foundIndex = this.part1.findIndex(x => x.id === planObj.id);
        this.part1[this.foundIndex] = planObj;
        console.log("part 1 updated");
        console.log(this.part1);
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "2":
        console.log("part 2");
        this.foundIndex = this.part2.findIndex(x => x.id === planObj.id);
        this.part2[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "3":
        console.log("part 3");
        this.foundIndex = this.part3.findIndex(x => x.id === planObj.id);
        this.part3[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "4":
        console.log("part 4");
        this.foundIndex = this.part4.findIndex(x => x.id === planObj.id);
        this.part4[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "5":
        console.log("part 5");
        this.foundIndex = this.part5.findIndex(x => x.id === planObj.id);
        this.part5[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "6":
        console.log("part 6");
        this.foundIndex = this.part6.findIndex(x => x.id === planObj.id);
        this.part6[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "7":
        console.log("part 7");
        this.foundIndex = this.part6.findIndex(x => x.id === planObj.id);
        this.part6[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part7, ...this.part7];
        break;
    }

    console.log('timeline');
    console.log(timeline);

    this.apiService.saveTimetable(timeline, id).subscribe(
        () => {},
        () => {},
        () => {
          this.addForm.reset();
          this.showSuccess();
        }
    );

  }

  dismissOnSuccess(): void {
    this.modalCtrl.dismiss({
      success: true
    }).then();
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      success: false
    }).then();
  }

  showSuccess(): void {
    this.presentToastSuccess().then(
        () => {
          this.loadingController.dismiss().then();
          this.dismissOnSuccess();
        }
    );
  }

  showError(): void {
    this.presentToastError().then(
        () => {}
    );
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Block erfolgreich aktualisiert!',
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

  async presentToastError() {
    const toast = await this.toastController.create({
      message: 'Fehler! Eingaben prüfen und erneut versuchen!',
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading',
    });
    await loading.present();
  }

}
