import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'add-plan',
  templateUrl: './add-plan.page.html',
  styleUrls: ['./add-plan.page.scss'],
})
export class AddPlanPage implements OnInit {

  addForm: FormGroup;

  // Form state
  public error = false;

  public saison: any;

  constructor(
      private fb: FormBuilder,
      public modalCtrl: ModalController,
      public toastController: ToastController,
      public loadingController: LoadingController,
      public apiService: ApiService
  ) {}

  ngOnInit(): void {

    this.saison = JSON.parse(localStorage.getItem('regie_cloud_seasons'));

    this.addForm = this.fb.group({
      title: ['', [
        Validators.required
      ]],
      date: new FormControl(),
      startTime: new FormControl(),
      opponent: ['', [
        Validators.required
      ]],
      saison: new FormControl(),
      typ: new FormControl(),
    });

  }

  submitHandler(): void {
    this.presentLoading().then();

    const gameDate = moment(this.addForm.value.date).format('YYYY-MM-DD');
    const gameTime  = moment(this.addForm.value.startTime).format('YYYY-MM-DD HH:mm:ss');

    const planObj = {
      title: this.addForm.value.title,
      opponent: this.addForm.value.opponent,
      saison: this.addForm.value.saison,
      date: gameDate,
      start_time: gameTime,
      end_time: '',
    };

    setTimeout(() => {
      this.addForm.reset();
      this.showSuccess();
    }, 2000);

    this.apiService.saveDay(planObj).subscribe(
      () => {},
      () => {},
      () => {
        this.addForm.reset();
        this.showSuccess();
      }
    );

  }

  dismissOnSuccess(): void {
    this.modalCtrl.dismiss({
      success: true
    }).then();
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      success: false
    }).then();
  }

  showSuccess(): void {
    this.presentToastSuccess().then(
        () => {
          this.loadingController.dismiss().then();
          this.dismissOnSuccess();
        }
    );
  }

  showError(): void {
    this.presentToastError().then(
        () => {}
    );
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Plan erfolgreich gespeichert!',
      duration: 2000,
      color: 'success'
    });
    await toast.present();
  }

  async presentToastError() {
    const toast = await this.toastController.create({
      message: 'Fehler! Eingaben prüfen und erneut versuchen!',
      duration: 2000,
      color: 'danger'
    });
    await toast.present();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading',
    });
    await loading.present();
  }

}
