import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
const { SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  constructor(
    private platform: Platform,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      // Custom style only for mobile
      const isAvailable = Capacitor.isPluginAvailable('StatusBar');
      if (isAvailable) {
        StatusBar.setStyle({
          style: StatusBarStyle.Light
        }).then();
      }

      SplashScreen.show({
        showDuration: 2000,
        autoHide: true
      }).then();

    });
  }
}
