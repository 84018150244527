import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginPage } from './pages/login/login.page';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditPlanComponent } from './pages/edit-plan/edit-plan.component';
import { ShareModule } from './share.module';
import { AddBlockPageModule } from './modals/add-block/add-block.module';
import { EditBlockPageModule } from "./modals/edit-block/edit-block.module";
import { EditBlockTimePageModule } from "./modals/edit-block-time/edit-block-time.module";
import { ManagePufferPageModule } from "./modals/manage-puffer/manage-puffer.module";
import { PlanUserPage } from "./pages/plan-user/plan-user.page";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EditPlanComponent,
    LoginPage,
    PlanUserPage
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    DashboardModule,
    DragDropModule,
    ShareModule,
    AddBlockPageModule,
    EditBlockPageModule,
    EditBlockTimePageModule,
    ManagePufferPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
