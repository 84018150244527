import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EditPlanComponent } from './pages/edit-plan/edit-plan.component';
import { PlanUserPage } from "./pages/plan-user/plan-user.page";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'edit-plan/:id',
    component: EditPlanComponent
  },
  {
    path: 'plan',
    loadChildren: './pages/plan/plan.module#PlanModule'
  },
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'plan-user/:id',
    component: PlanUserPage
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
