import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private BASE_URL = environment.SERVER_URL;

  constructor(
      private http: HttpClient,
  ) { }

  /* API calls */
  deleteTimetable(id: any) {

    return this.http.delete(`${this.BASE_URL}timetables/${id}`);
  }

  deleteTourbooks(id: any) {

    return this.http.delete(`${this.BASE_URL}tourbooks/${id}`);
  }

  getAllTourbooks() {
    return this.http.get(`${this.BASE_URL}tourbooks`);
  }

  getTourbook(id: any) {
    return this.http.get(`${this.BASE_URL}tourbooks/${id}`);
  }

  getSeasons() {
    return this.http.get(`${this.BASE_URL}seasons`);
  }

  getCategories() {
    return this.http.get(`${this.BASE_URL}categories`);
  }

  getParts() {
    return this.http.get(`${this.BASE_URL}parts`);
  }

  /*
  getTimeline(id: any, part: any) {
    return this.http.get(`${this.BASE_URL}php/neu/public/tourbooks/${id}/timetables`);
  }
  */

  getTimeline(id: any, part: any) {
    console.log(part);
    return this.http.get(`${this.BASE_URL}tourbooks/${id}/${part}/timetables`);
  }

  saveTimetable(value: any, id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(`${this.BASE_URL}tourbooks/${id}/timetables`, value, httpOptions);
  }

  saveDay(value: any) {

    let season = '';
    if (value.saison) {
      season = value.saison;
    } else {
      season = value.season;
    }

    const data = {
      title: value.title,
      opponent: value.opponent,
      season: season,
      date: value.date,
      start_time: value.start_time,
      end_time: value.end_time
    };

    console.log(data);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };


    return this.http.post(`${this.BASE_URL}tourbooks`, data, httpOptions);
  }

  editDay(value: any, id: any) {

    const data = {
      id: value.id,
      title: value.title,
      opponent: value.opponent,
      season: value.saison,
      date: value.date,
      start_time: value.start_time,
      end_time: value.end_time
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http.patch(`${this.BASE_URL}tourbooks/${id}`, data, httpOptions);
  }

  duplicateDay(id: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this.http.post(`${this.BASE_URL}tourbooks/${id}/duplicate`, '', httpOptions);
  }

}
