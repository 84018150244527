import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { NavController, ToastController } from '@ionic/angular';

import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // TODO auth service muss noch implementiert werden sobald user aus DB kommen.

  private BASE_URL = environment.SERVER_URL;

  public logoutMessage: string;

  constructor(
      private http: HttpClient,
      private router: Router,
      public toastController: ToastController,
      private navCtrl: NavController
  ) {}

  getToken(): string {
    return '';
  }

  getUserId(): string {
    return '';
  }

  forgotPassword(email: string): Observable<any> {
    const url = `${this.BASE_URL}/password/reset`;
    return this.http.post<any>(url, email);
  }

  resetPassword(password, hash): Observable<any> {
    const url = `${this.BASE_URL}/password/update`;
    return this.http.post<any>(url, {token: hash, password: password});
  }

  logIn(email: string, password: string, language: string, ip: string) {
    const url = `${this.BASE_URL}/token`;
    return this.http.post<any>(url, { email: email, password: password, ip: ip, lang: language })
        .pipe(map((res: any) => {
          // console.log(res);
          // login successful if there's a jwt token in the response
          if (res && res.token) {

          }
        }));
  }

  logout(): void {
    // remove user from local storage to log user out
    this.navCtrl.navigateRoot('/login').then(
        () => {
          localStorage.removeItem('regieCloudAdmin');

          this.presentToast('ausgeloggt').then()

        }
    );
  }

  isLoggedIn(): boolean {
    return false;
  }

  // present toast on page
  async presentToast(responseMessage) {
    const toast = await this.toastController.create({
      color: 'success',
      message: responseMessage,
      duration: 2000
    });
    toast.present();
  }

}
