import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seasonById'
})
export class SeasonByIdPipe implements PipeTransform {

  // TODO Season sollte aus DB kommen, nicht mehr lokal
  public seasons = JSON.parse(localStorage.getItem('regie_cloud_seasons'));

  transform(value: any, args?: any): any {

    if (!value) {
      return null;
    }

    const result = this.seasons.find(obj => {
      return obj.id === value.toString();
    });

    return result.season_name;
  }

}
