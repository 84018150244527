import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavController, PopoverController, ToastController } from "@ionic/angular";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  loginForm: FormGroup;

  // Form state
  loading = false;
  success = false;
  error = false;
  isValidFormSubmitted = null;

  public lang: string;

  constructor(
      private fb: FormBuilder,
      private auth: AuthService,
      private toastController: ToastController,
      private popoverCtrl: PopoverController,
      private navControler: NavController,
  ) { }

  ngOnInit() {

    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required
      ]],
    });

  }

  submitHandler() {
    this.loading = true;
    this.isValidFormSubmitted = false;
    const formValue = this.loginForm.value;
    console.log(formValue);

    if (formValue.email === 'admin@regie-cloud.com') {
      console.log('admin');
      localStorage.setItem('regieCloudAdmin', 'true');
    } else if (formValue.email === 'user@regie-cloud.com') {
      console.log('user');
      localStorage.setItem('regieCloudAdmin', 'false');
    } else {
      console.log('not authorized');
      this.error = false;
      this.isValidFormSubmitted = false;
      this.loading = false;
      return;
    }

    if (this.loginForm.invalid) {
      this.error = true;
      this.loading = false;
      return;
    }

    this.isValidFormSubmitted = true;

    this.loading = false;
    this.showSuccess().then();
    this.navControler.navigateForward('/dashboard').then();

    // TODO login via api
    /*
    this.authService.logIn(this.loginForm.value['email'], this.loginForm.value['password']).subscribe(
        () => {
        },
        (error) => {
          console.log(error);
          this.error = true;
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.showSuccess().then(
              () => {
                this.authService.changeLogin(true);
                this.navControler.navigateRoot(['/tabs']).then();
              }
          );
        }
    );
    */

  }

  async showSuccess() {

    let successMessage = 'Erfolgreich angemeldet!';

    const toast = await this.toastController.create({
      color: 'success',
      message: successMessage,
      duration: 2000
    });
    toast.present().then();
  }

}
