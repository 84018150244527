import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { AlertController, LoadingController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { AddPlanPage } from '../../modals/add-plan/add-plan';
import { HotkeysService } from '../../services/hotkeys.service';
import { ExportPage } from "../../popover/export/export.page";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  public plans: any;
  public showPlan: any;
  public singlePlan: any;
  public season: any;
  public cats: any;
  public parts: any;

  public isAdmin: string;

  public selectedPlans = 'neuste';

  constructor(
      public auth: AuthService,
      public apiService: ApiService,
      public loadingController: LoadingController,
      public navCtrl: NavController,
      public alertController: AlertController,
      public modalController: ModalController,
      private hotkeys: HotkeysService,
      public popoverController: PopoverController
  ) { }

  ngOnInit(): void {}

  ionViewDidEnter(): void {
    this.hotkeys.addShortcut({ keys: 'shift.!' }).subscribe(() => {
      this.addPlan();
    });

    this.isAdmin = localStorage.getItem('regieCloudAdmin');

    this.getData();
  }

  ngOnDestroy(): void {}

  ionViewDidLeave(): void {
    this.subscription.unsubscribe();
  }

  getData() {
    this.presentLoading().then(
        () => {
          this.subscription = this.apiService.getSeasons().subscribe(
              (res) => {
                this.season = res;
                localStorage.removeItem('regie_cloud_seasons');
                localStorage.setItem('regie_cloud_seasons', JSON.stringify(this.season));
              },
              ( ) => {},
              () => {}
          );

          this.subscription = this.apiService.getCategories().subscribe(
              (res) => {
                this.cats = res;
                localStorage.removeItem('regie_cloud_categories');
                localStorage.setItem('regie_cloud_categories', JSON.stringify(this.cats));
              },
              () => {},
              () => {}
          );

          this.subscription = this.apiService.getParts().subscribe(
              (res) => {
                this.parts = res;
                localStorage.removeItem('regie_cloud_parts');
                localStorage.setItem('regie_cloud_parts', JSON.stringify(this.parts));
              },
              () => {},
              () => {}
          );

          this.subscription = this.apiService.getAllTourbooks().subscribe(
              (res) => {
                // @ts-ignore
                const plans = res.reverse();
                const singelPlans = [];
                singelPlans.push(plans[0]);
                this.plans = res;
                this.showPlan = singelPlans;
                this.singlePlan = singelPlans;
                this.loadingController.dismiss().then();
              },
              () => {
                this.loadingController.dismiss().then();
              },
              () => {}
          );
        }
    );
  }

  startPlan(id: string) {
    this.navCtrl.navigateRoot(`/plan/part-1/${id}`).then();
  }

    startPlanUser(id: string) {
        this.navCtrl.navigateRoot(`/plan-user/${id}`).then();
    }

  duplicatePlan(id: string) {

    this.presentAlertDuplicate(id).then();

  }

  duplicatePlanSave(id: string) {
    this.apiService.duplicateDay(id).subscribe(
        () => {},
        () => {},
        () => {
          console.log('reload data on plan duplicate event');
          this.getData();
        }
    );
  }

  deletePlan(id: string) {

    this.presentAlertDelete(id).then();

  }

  deletePlanSave(id: string) {
    this.apiService.deleteTourbooks(id).subscribe(
        () => {},
        () => {},
        () => {
          console.log('reload data on plan delete event');
          this.getData();
        }
    );
  }

  editPlan(id: string) {
    this.navCtrl.navigateRoot(`/edit-plan/${id}`).then();
  }

  addPlan() {
    this.presentModal().then();
  }

  changePlans($event: any) {

      const value = $event.detail.value;
      const headline = document.getElementById('headline');

      $event.target.setAttribute('value', $event.detail.value);

      $event.target.childNodes.forEach((elm) => {
          elm.removeAttribute('selected');

          if (elm.getAttribute('value') === $event.detail.value) {
              console.log(elm.getAttribute('value'));
              elm.classList.add('item-radio-checked');
              elm.setAttribute('selected', 'true');
          }
      });

      switch (value) {
          case 'alle':
              headline.innerText = 'Alle Regiepläne';
              this.showPlan = this.plans;
              break;
          case 'neuste':
              headline.innerText = 'Neuster Regieplan';
              this.showPlan = this.singlePlan;
              break;
      }

  }

  logout(): void {
    this.auth.logout();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading',
    });
    await loading.present();
  }

    async presentExportaPopover(env: any, id: any) {
        const popover = await this.popoverController.create({
            component: ExportPage,
            event: env,
            componentProps: {
                id: id,
            },
            translucent: true
        });
        return await popover.present();
    }

  async presentAlertDelete(id: string) {
    const alert = await this.alertController.create({
      header: 'Plan löschen!',
      message: 'Sind Sie sicher, dass sie den Plan unwiederruflich löschen möchten?',
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Löschen',
          handler: () => {
            this.deletePlanSave(id);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertDuplicate(id: string) {
    const alert = await this.alertController.create({
      header: 'Plan duplizieren!',
      message: 'Sind Sie sicher, dass sie den Plan duplizieren möchten?',
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Duplizieren',
          handler: () => {
            this.duplicatePlanSave(id);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: AddPlanPage,
    });

    modal.onDidDismiss()
      .then((data) => {
        // @ts-ignore
        if (true === data.data.success ) {
          console.log('reload data on plan add event');
          this.getData();
        }
      });

    return await modal.present();
  }

}
