import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AddBlockPage } from '../../modals/add-block/add-block';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { GetDurationService } from "../../services/get-duration.service";
import { EditBlockPage } from "../../modals/edit-block/edit-block";

@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss'],
})
export class EditPlanComponent implements OnInit, OnDestroy {

  addForm: FormGroup;

  public id: string;

  // Form state
  public error = false;

  public subscription: Subscription;

  public saison: any;
  public plan: any;
  public parts1: any;
  public parts1Duration: any;
  public parts2: any;
  public parts2Duration: any;
  public parts3: any;
  public parts3Duration: any;
  public parts4: any;
  public parts4Duration: any;
  public parts5: any;
  public parts5Duration: any;
  public parts6: any;
  public parts6Duration: any;
  public parts7: any;
  public parts7Duration: any;

  constructor(
      private fb: FormBuilder,
      public auth: AuthService,
      public route: ActivatedRoute,
      public navCtrl: NavController,
      public apiService: ApiService,
      public loadingController: LoadingController,
	  public alertController: AlertController,
      public modalController: ModalController,
	  public toastController: ToastController,
      public duration: GetDurationService
  ) { }

  ngOnInit(): void {}

  ionViewWillEnter(): void {}

  ionViewDidEnter(): void {
	this.parts2=[];
	this.parts4=[];
	this.parts5=[];
	this.parts6=[];  
    this.getData('first', 0);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ionViewWillLeave(): void {}

  getData(action: string, partID: number) {
    this.route.params.subscribe(
      (res) => {
		 this.id = res.id;
		if(action=='first'){
			this.getTourbook(res.id);
			this.getTimelines(res.id, '',0);
		}else {
			this.getTimelines(res.id, action, partID);
		}       
      },
      () => {},
      () => {}
    );
  }

  getTourbook(id: string) {
    this.subscription = this.apiService.getTourbook(id).subscribe(
      (data) => {
        this.plan = data;
      },
      () => {},
      () => {

        if (this.plan) {
		console.log('Plan Data', this.plan); 
          this.saison = JSON.parse(localStorage.getItem('regie_cloud_seasons'));		
          this.addForm = this.fb.group({
            title: this.plan.title,
            date: moment(this.plan.date).format('YYYY-MM-DD'),
            startTime: moment(this.plan.start_time).format('YYYY-MM-DDTHH:mm:ss'),
			//startTime: "2021-09-01T20:00:00Z",
            opponent: this.plan.opponent,
            saison: this.plan.season,
          });
        }

      }
    );
  }

  // TODO fix loading and get observables with forkjoin http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#static-method-forkJoin
  getTimelines(id: string, action: string, partID: number) {

    // this.presentLoading().then();
      this.subscription = this.apiService.getTimeline(id, 1).subscribe(
          (data) => {
              this.parts1 = data;
              this.parts1Duration = this.duration.getPartDuration(this.parts1);			 
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 3).subscribe(
          (data) => {
              this.parts3 = data;
              this.parts3Duration = this.duration.getPartDuration(this.parts3);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 7).subscribe(
          (data) => {
              this.parts7 = data;
              this.parts7Duration = this.duration.getPartDuration(this.parts7);                          
          },
          () => {},
          () => {}
      );
	  
	  if(action=='next'){
		  this.addBlock(partID)
	  }
	   
  }

    /*
    * Drag and drop list items
    */
    drop(event: CdkDragDrop<string[]>, part: any) {
        // TODO update real data via api

        if (event.previousContainer === event.container) {
            console.log('drag');
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            console.log(event.container.data);
        } else {}

        let timeline = [];

        switch (part.toString()) {
            case "1":
                console.log("part 1");
                timeline = [...event.container.data, ...this.parts2, ...this.parts3, ...this.parts4, ...this.parts5, ...this.parts6, ...this.parts7];
                break;            
            case "3":
                console.log("part 3");
                timeline = [...this.parts1, ...this.parts2, ...event.container.data, ...this.parts4, ...this.parts5, ...this.parts6, ...this.parts7];
                break;           
            case "7":
                console.log("part 7");
                timeline = [...this.parts1, ...this.parts2, ...this.parts3, ...this.parts4, ...this.parts5, ...this.parts7, ...event.container.data];
                break;
        }

        console.log(timeline);

        this.subscription = this.apiService.saveTimetable(timeline, this.plan.id).subscribe(
            () => {},
            () => {},
            () => {
                this.subscription.unsubscribe();
                this.getData('',0);
            }
        );
    }

  addBlock(part: number) {
    this.presentAddModal(part, this.plan.id).then();
  }

  editItem(block: any, part: number) {
      this.presentEditModal(part, block, this.plan.id).then();
  }

  deleteItem(id: string) {
    console.log(id);
	this.presentAlertDelete(id).then();
    // TODO open delete alert and update via api
  }
   deleteBlockSave(id: string) {
    this.apiService.deleteTimetable(id).subscribe(
        () => {},
        () => {},
        () => {
          console.log('reload data on plan delete event');
          this.getData('',0);
        }
    );
  }

  submitHandler(): void {
    // this.presentLoading().then();

    console.log(this.addForm.value);

    const gameDate = moment(this.addForm.value.date).format('YYYY-MM-DD');	
    const gameTime  = moment(this.addForm.value.startTime).format('YYYY-MM-DD HH:mm:ss');
	

    console.log(gameDate);
    console.log('gameTime Formated',gameTime);	

    const planObj = {
      title: this.addForm.value.title,
      opponent: this.addForm.value.opponent,
      saison: this.addForm.value.saison,
      date: gameDate,
      start_time: gameTime,
      end_time: '',
    };

    this.subscription = this.apiService.editDay(planObj, this.id).subscribe(
        () => {},
        () => {},
        () => {
          this.getData('',0);
		  this.presentToastSuccess();
        }
    );

  }

  public trackItem(index: number, item: any) {
    return item.trackId;
  }

  back(): void {
    this.navCtrl.navigateRoot('dashboard').then();
  }

  logout(): void {
    this.auth.logout();
  }

  async presentAddModal(parts, id) {
    const modal = await this.modalController.create({
      component: AddBlockPage,
      componentProps: {
        part1: this.parts1,
        part2: this.parts2,
        part3: this.parts3,
        part4: this.parts4,
        part5: this.parts5,
        part6: this.parts6,
        part7: this.parts7,
        part: parts,
        id: id
      }
    });

    modal.onDidDismiss()
    .then((data) => {
      // @ts-ignore
      if (true === data.data.success ) {		  
			 console.log('reload data on block add event');
			 this.getData(data.data.action, data.data.partid)


      }
    });

    return await modal.present();
  }

    async presentEditModal(parts, block, id) {
        const modal = await this.modalController.create({
            component: EditBlockPage,			
            componentProps: {
                part1: this.parts1,
                part2: this.parts2,
                part3: this.parts3,
                part4: this.parts4,
                part5: this.parts5,
                part6: this.parts6,
                part7: this.parts7,
                part: parts,
                id: id,
                block: block
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                // @ts-ignore
                if (true === data.data.success ) {
                    console.log('reload data on block add event');
                    this.getData('edit', 0);
                }
            });

        return await modal.present();
    }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading',
    });
    await loading.present();
  }
  
    async presentAlertDelete(id: string) {
    const alert = await this.alertController.create({
      header: 'Block löschen!',
      message: 'Sind Sie sicher, dass sie den Block unwiederruflich löschen möchten?',
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Löschen',
          handler: () => {
            this.deleteBlockSave(id);
          }
        }
      ]
    });

    await alert.present();
  }
  
  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Plan erfolgreich gespeichert!',
      duration: 2000,
      color: 'success'
    });
    await toast.present();
  }

}
