import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AddPlanPageModule } from '../../modals/add-plan/add-plan.module';
import { ExportPageModule } from "../../popover/export/export.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    AddPlanPageModule,
    ExportPageModule
  ],
  entryComponents: []
})
export class DashboardModule { }
