import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EditBlockPage } from './edit-block';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
    ],
    declarations: [EditBlockPage],
    entryComponents: [
        EditBlockPage
    ]
})
export class EditBlockPageModule {}
