import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-export',
  templateUrl: './export.page.html',
  styleUrls: ['./export.page.scss'],
})
export class ExportPage implements OnInit {

  id: string;

  constructor(
      private popoverCtrl: PopoverController,
      private navParams: NavParams
  ) { }

  ngOnInit(): void {
    this.id = this.navParams.get('id');
  }

}
