import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: "[numberOnly]"
})
export class NumberOnlyDirective implements OnInit {

  @Input() numberOnly: string;
  private regex: RegExp;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Clear',
    'Copy', 'Control', 'Del', 'Shift', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.regex = new RegExp(this.numberOnly, "g");
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.substring(0, this.el.nativeElement.selectionStart) + event.key + current.substring(this.el.nativeElement.selectionEnd)

    if (next && !String(next).match(this.regex)) { 
        event.preventDefault();
    }
  }
}
