import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'add-block',
  templateUrl: './add-block.page.html',
  styleUrls: ['./add-block.page.scss'],
})
export class AddBlockPage implements OnInit {

  @Input() id: string;
  @Input() part: string;

  @Input() part1: any;
  @Input() part2: any;
  @Input() part3: any;
  @Input() part4: any;
  @Input() part5: any;
  @Input() part6: any;
  @Input() part7: any;

  addForm: FormGroup;

  // Form state
  public error = false;

  public categories: any;

  constructor(
      private fb: FormBuilder,
      public modalCtrl: ModalController,
      public toastController: ToastController,
      public loadingController: LoadingController,
      public apiService: ApiService
  ) {}

  ngOnInit(): void {

    console.log(this.part);
    console.log(this.id);

    this.categories = JSON.parse(localStorage.getItem('regie_cloud_categories'));

    this.addForm = this.fb.group({
      category: new FormControl(),
      row: ['', []],
      content: ['', [
        Validators.required
      ]],
      durationMinutes: ['', [
        Validators.required
      ]],
      durationSeconds: ['', [
        Validators.required
      ]],
      remarks: ['', []],
	  pufferBool: [false, []],
	  specialBool: [false, []]	 
    });

  }

  submitHandler(part, id, action): void { 
	this.presentLoading().then();
		
	let pufferInt =0;
	let specialInt =0;
	if(this.addForm.value.pufferBool===true){
		pufferInt=1;
	}
	if(this.addForm.value.specialBool===true){
		specialInt=1;
	}
	let min = this.addForm.value.durationMinutes;
    if (min.length === 1) {
      min = `0${this.addForm.value.durationMinutes}`;
    } else if (min.length < 1) {
      min = '00';
    }

    let sec = this.addForm.value.durationSeconds;
    if (sec.length === 1) {
      sec = `0${this.addForm.value.durationSeconds}`;
    } else if (sec.length < 1) {
      sec = '00';
    }
	
    const planObj = {
      id: 0,
      tourbook: parseInt(id, 10),
      cat: parseInt(this.addForm.value.category, 10),
      row: this.addForm.value.row,
      content: this.addForm.value.content,
      duration: `00:${min}:${sec}`,
      durationMinutes: min,
      durationSeconds: sec,
      part_id: part,
      remarks: this.addForm.value.remarks,
      sort: 0,
      active: 0,
      finished: 0,
      done: 0,
	  puffer: pufferInt,
	  special: specialInt,	  
    };
	//Fehler abfangen
	if(planObj.content =="" || this.addForm.value.category ==null){
		this.showError();
	}else{
		let timeline = [];

		switch (part.toString()) {
		  case "1":
			console.log("part 1", planObj);
			console.log("action", action);
			this.part1.push(planObj);
			timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
			break;      
		  case "3":
			console.log("part 3");
			this.part3.push(planObj);
			timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
			break;     
		  case "7":
			console.log("part 7");
			this.part7.push(planObj);
			timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
			break;
		}

		this.apiService.saveTimetable(timeline, id).subscribe(
		  () => {},
		  () => {},
		  () => {
			this.addForm.reset();			
			this.showSuccess(action);
		  }
		);
	}


  }

  dismissOnSuccess(action): void {
    this.modalCtrl.dismiss({
      success: true,
	  action: action,
	  partid: this.part
    }).then();
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      success: false
    }).then();
  }

  showSuccess(action): void {
    this.presentToastSuccess().then(
        () => {
			this.loadingController.dismiss().then();			
			this.dismissOnSuccess(action);
			        
        }
    );
  }

  showError(): void {
    this.presentToastError().then(
        () => {
			this.loadingController.dismiss().then();
		}
    );
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Block erfolgreich hinzugefügt!',
      duration: 2000,
      color: 'success'
    });
    await toast.present();
  }

  async presentToastError() {
    const toast = await this.toastController.create({
      message: 'Fehler! Eingaben prüfen und erneut versuchen!',
      duration: 2000,
      color: 'danger'
    });
    await toast.present();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading',
    });
    await loading.present();
  }

}
