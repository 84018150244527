import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AddPlanPage } from './add-plan';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
    ],
    declarations: [AddPlanPage],
    entryComponents: [AddPlanPage]
})
export class AddPlanPageModule {}
