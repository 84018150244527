import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'catById'
})
export class CatByIdPipe implements PipeTransform {

  // TODO Category sollte aus DB kommen, nicht mehr lokal
  public categories = JSON.parse(localStorage.getItem('regie_cloud_categories'));

  transform(value: any, args?: any): any {

    if (!value) {
      return null;
    }

    const result = this.categories.find(obj => {
      return obj.id === value.toString();
    });

    return result.cat_name;
  }

}
