import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute } from "@angular/router";
import { LoadingController, ModalController, NavController } from "@ionic/angular";
import { ApiService } from "../../services/api.service";
import { GetDurationService } from "../../services/get-duration.service";

@Component({
  selector: 'app-plan-user',
  templateUrl: './plan-user.page.html',
  styleUrls: ['./plan-user.page.scss'],
})
export class PlanUserPage implements OnInit, OnDestroy {
  public id: string;

  public subscription: Subscription;

  public saison: any;
  public plan: any;
  public parts1: any;
  public parts1Duration: any;
  public parts2: any;
  public parts2Duration: any;
  public parts3: any;
  public parts3Duration: any;
  public parts4: any;
  public parts4Duration: any;
  public parts5: any;
  public parts5Duration: any;
  public parts6: any;
  public parts6Duration: any;
  public parts7: any;
  public parts7Duration: any;

  public now: Date = new Date();

  constructor(
      public auth: AuthService,
      public route: ActivatedRoute,
      public navCtrl: NavController,
      public apiService: ApiService,
      public loadingController: LoadingController,
      public duration: GetDurationService
  ) {
      setInterval(() => {
          this.now = new Date();
      }, 1);
  }

  ngOnInit(): void {}

  ionViewWillEnter(): void {}

  ionViewDidEnter(): void {
    this.getData();

    setInterval(() => {
        this.getData();
    }, 2000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ionViewWillLeave(): void {}

  getData() {
    this.route.params.subscribe(
        (res) => {
          this.id = res.id;
          this.getTourbook(res.id);
          this.getTimelines(res.id);
        },
        () => {},
        () => {}
    );
  }

  getTourbook(id: string) {
    this.subscription = this.apiService.getTourbook(id).subscribe(
        (data) => {
          this.plan = data;
        },
        () => {},
        () => {}
    );
  }

  // TODO fix loading like at arm

  getTimelines(id: string) {

    // this.presentLoading().then();

      this.subscription = this.apiService.getTimeline(id, 1).subscribe(
          (data) => {
              this.parts1 = data;
              this.parts1Duration = this.duration.getPartDuration(this.parts1);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 2).subscribe(
          (data) => {
              this.parts2 = data;
              this.parts2Duration = this.duration.getPartDuration(this.parts2);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 3).subscribe(
          (data) => {
              this.parts3 = data;
              this.parts3Duration = this.duration.getPartDuration(this.parts3);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 4).subscribe(
          (data) => {
              this.parts4 = data;
              this.parts4Duration = this.duration.getPartDuration(this.parts4);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 5).subscribe(
          (data) => {
              this.parts5 = data;
              this.parts5Duration = this.duration.getPartDuration(this.parts5);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 5).subscribe(
          (data) => {
              this.parts6 = data;
              this.parts6Duration = this.duration.getPartDuration(this.parts6);
          },
          () => {},
          () => {}
      );
      this.subscription = this.apiService.getTimeline(id, 7).subscribe(
          (data) => {
              this.parts7 = data;
              this.parts7Duration = this.duration.getPartDuration(this.parts7);
              console.log('dismiss');
              // this.loadingController.dismiss().then();
          },
          () => {},
          () => {}
      );
  }

  public trackItem(index: number, item: any) {
    return item.trackId;
  }

  back(): void {
    this.navCtrl.navigateRoot('dashboard').then();
  }

  logout(): void {
    this.auth.logout();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Aktualisieren',
    });
    await loading.present();
  }

}

