import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationPipe'
})
export class DurationPipe implements PipeTransform {

  // Korrekte Darstellung des Zeitraumsstrings
  transform(value: any, args?: any): any {
     let min = '';
     let second = '';
     let time = '';

     if (value) {
       const times = value.split(':');

       const minLength = times[0].length;
       const secondLenth = times[1].length;

       if (minLength <= 1) {
         min = `0${times[0]}`;
       } else {
         min = times[0];
       }

       if (secondLenth <= 1) {
         second = `0${times[1]}`;
       } else {
         second = times[1];
       }

       time = `${min}:${second}`;
     }

    return time;
  }

}
