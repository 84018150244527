import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-manage-puffer',
  templateUrl: './manage-puffer.page.html',
  styleUrls: ['./manage-puffer.page.scss'],
})
export class ManagePufferPage implements OnInit {

  addForm: FormGroup;

  @Input() pufferList: any;
  @Input() time: number;
  @Input() operation: boolean;

  rest: number;
  formValid: any = {};
  oldValue: string = '0';
  changedId: string = '-1';
  changedTime: number = 0;


  constructor(
    private fb: FormBuilder,
    public modalCtrl: ModalController,
    public toastController: ToastController,
    public apiService: ApiService) {
  }

  ngOnInit() {
    this.rest = this.time;
    for (let { item, index } of this.pufferList.map((item: any, index: number) => ({ item, index }))) {
      let time = this.timeToSeconds(item.durationMinutes, item.durationSeconds);
      this.pufferList[index].time = time;

      if (this.operation) {
        this.formValid[item.id] = [this.rest, Validators.required];
        this.rest = 0;
      } else {
        if (this.rest > 0) {
          if (this.rest <= time) {
            this.formValid[item.id] = [{ value: this.rest, disabled: time == 0 }, Validators.required];
            this.rest = 0;
          } else {
            this.formValid[item.id] = [{ value: time, disabled: time == 0 }, Validators.required];
            this.rest -= time;
          }
        } else {
          this.formValid[item.id] = [0, Validators.required];
        }
      }

      if (time != 0 && this.changedId == '-1') {
        this.changedId = item.id;
        this.changedTime = time;
      }
    }
    this.addForm = this.fb.group(this.formValid);
  }

  changeValue(id: string, index: number, value: any): void {
    let oldValue = this.toInt(this.oldValue);
    const changedId: string = this.changedId;
    const empty = value.length == 0;
    value = this.toInt(value);

    if (oldValue !== value) {
      let diff: number = value - oldValue;
      let newValue: number = this.f[changedId].value - diff;

      if (this.operation) {
        if (newValue >= 0) {
          this.oldValue = value;
          this.addForm.controls[changedId].setValue(newValue);
        }
        const allTime = Object.entries(this.addForm.value).map(([id, value]) => {
          let time = this.toInt(value);
          return ({ id, time })
        }).reduce((sum, x) => sum + x.time, 0);

        if (allTime != this.time) {
          this.addForm.controls[id].setValue(oldValue);
        }
      } else {
        if (value <= this.pufferList[index].time && newValue >= 0 && newValue <= this.changedTime) {
          this.oldValue = value;
          this.addForm.controls[changedId].setValue(newValue);
        }
        else {
          if (!empty) {
            this.addForm.controls[id].setValue(oldValue);
          }
        }
      }
    }

  }

  onBlur(id: string, value: any): void {
    const allTime = Object.entries(this.addForm.value).map(([id, value]) => {
      let time = this.toInt(value);
      return ({ id, time })
    }).reduce((sum, x) => sum + x.time, 0);
    if (allTime != this.time) {
      this.addForm.controls[id].setValue(this.oldValue);
    } else if (value.length == 0) {
      this.addForm.controls[id].setValue(this.oldValue);
    }
  }

  toInt(value) {
    const x = parseInt(value, 10);
    if (isNaN(x)) {
      return 0;
    }
    return x;
  }

  get f() {
    return this.addForm.controls;
  }

  submitHandler(): void {
    if (this.addForm.valid) {
      this.modalCtrl.dismiss({
        success: true,
        pufferList: Object.entries(this.addForm.value).map(([id, value]) => {
          let time = this.toInt(value);
          return ({ id, time })
        })
      }).then();
    }
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      success: false
    }).then();
  }

  timeToSeconds(minutes: any, second: any) {
    const minSeconds = parseInt(minutes, 10) * 60;
    const seconds = parseInt(second, 10);
    return minSeconds + seconds;
  }

}
