import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'edit-block',
  templateUrl: './edit-block.page.html',
  styleUrls: ['./edit-block.page.scss'],
})
export class EditBlockPage implements OnInit {

  @Input() id: string;
  @Input() part: string;
  @Input() block: any;

  @Input() part1: any;
  @Input() part2: any;
  @Input() part3: any;
  @Input() part4: any;
  @Input() part5: any;
  @Input() part6: any;
  @Input() part7: any;

  addForm: FormGroup;

  // Form state
  public error = false;

  public categories: any;

  private foundIndex: any;

  constructor(
      private fb: FormBuilder,
      public modalCtrl: ModalController,
      public toastController: ToastController,
      public loadingController: LoadingController,
      public apiService: ApiService
  ) {}

  ngOnInit(): void {
	console.log('Block-Data', this.block);
	if(this.block.puffer==1){
		this.block.pufferBool=true;
	}else{
		this.block.pufferBool=false;
	}
	
	if(this.block.special==1){
		this.block.specialBool=true;
	}else{
		this.block.specialBool=false;
	}
    this.categories = JSON.parse(localStorage.getItem('regie_cloud_categories'));

    this.addForm = this.fb.group({
      category: new FormControl(this.block.cat),
      row: [this.block.row, []],
      content: [this.block.content, [
        Validators.required
      ]],
      durationMinutes: [this.block.durationMinutes, [
        Validators.required
      ]],
      durationSeconds: [this.block.durationSeconds, [
        Validators.required
      ]],
      remarks: [this.block.remarks, []],
	  pufferBool: [this.block.pufferBool, []],
	  specialBool: [this.block.specialBool, []]	  
    });

  }

  // Speichern des Bearbeiten blocks in der DB
  // TODO Kann vereinfacht werden, in API funktion integrieren die Anhand der ID nur den Block updated

  submitHandler(part, id): void {
	 let pufferInt =0;
	 let specialInt =0;
    //this.presentLoading().then();
	//Puffer von Boolean to Int
	if(this.addForm.value.pufferBool===true){
		pufferInt=1;
	}
	if(this.addForm.value.specialBool===true){
		specialInt=1;
	}
	
    let min = this.addForm.value.durationMinutes;
    if (min.length === 1) {
      min = `0${this.addForm.value.durationMinutes}`;
    } else if (min.length < 1) {
      min = '00';
    }

    let sec = this.addForm.value.durationSeconds;
    if (sec.length === 1) {
      sec = `0${this.addForm.value.durationSeconds}`;
    } else if (sec.length < 1) {
      sec = '00';
    }
	this.block.puffer=this.addForm.value.puffer;
    const planObj = {
      id: this.block.id,
      tourbook: parseInt(id, 10),
      cat: parseInt(this.addForm.value.category, 10),
      row: this.addForm.value.row,
      content: this.addForm.value.content,
      duration: `00:${min}:${sec}`,
      durationMinutes: min,
      durationSeconds: sec,
      part_id: part,
      remarks: this.addForm.value.remarks,
      sort: 0,
      active: 0,
      finished: 0,
      done: 0,
	  puffer: pufferInt,
	  special: specialInt,
    };

    let timeline = [];

    switch (part.toString()) {
      case "1":
        console.log("part 1");
        console.log(this.part1);
        // let obj = this.part1.find(obj => obj.id === planObj.id);
        this.foundIndex = this.part1.findIndex(x => x.id === planObj.id);
        this.part1[this.foundIndex] = planObj;
        console.log("part 1 updated");
        console.log(this.part1);
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;     
      case "3":
        console.log("part 3");
        this.foundIndex = this.part3.findIndex(x => x.id === planObj.id);
        this.part3[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part6, ...this.part7];
        break;
      case "7":
        console.log("part 7");
        this.foundIndex = this.part7.findIndex(x => x.id === planObj.id);
        this.part7[this.foundIndex] = planObj;
        timeline = [...this.part1, ...this.part2, ...this.part3, ...this.part4, ...this.part5, ...this.part7, ...this.part7];
        break;
    }

    console.log('timeline');
    console.log(timeline);

    this.apiService.saveTimetable(timeline, id).subscribe(
        () => {},
        () => {},
        () => {
          this.addForm.reset();
          this.showSuccess();
        }
    );

  }

  dismissOnSuccess(): void {
    this.modalCtrl.dismiss({
      success: true
    }).then();
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      success: false
    }).then();
  }

  showSuccess(): void {
    this.presentToastSuccess().then(
        () => {
          //this.loadingController.dismiss().then();
          this.dismissOnSuccess();
        }
    );
  }

  showError(): void {
    this.presentToastError().then(
        () => {}
    );
  }

  async presentToastSuccess() {
    const toast = await this.toastController.create({
      message: 'Block erfolgreich aktualisiert!',
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

  async presentToastError() {
    const toast = await this.toastController.create({
      message: 'Fehler! Eingaben prüfen und erneut versuchen!',
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Loading',
    });
    await loading.present();
  }

}
