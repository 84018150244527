import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetDurationService {

  constructor() { }

  // Rückgabe der Dauer
  getPartDuration(parts: any) {

    let seconds = 0;
    let minutes = 0;

    parts.forEach((part) => {
      seconds = parseInt(part.durationSeconds, 10) + seconds;
    });

    const alterSeconds = seconds % 60;
    const alterMinutes = ~~((seconds % 3600) / 60);

    parts.forEach((part) => {
      minutes = parseInt(part.durationMinutes, 10) + minutes;
    });

    const time = `${(minutes + alterMinutes).toString()}:${alterSeconds.toString()}`;

    return time;
  }
}
