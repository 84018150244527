import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SeasonByIdPipe } from './pipes/season-by-id.pipe';
import { CatByIdPipe } from './pipes/cat-by-id.pipe';
import { DurationPipe } from "./pipes/duration.pipe";

@NgModule({
  declarations: [
    SeasonByIdPipe,
    CatByIdPipe,
    DurationPipe
  ],
  entryComponents: [],
  imports: [],
  exports: [
    SeasonByIdPipe,
    CatByIdPipe,
    DurationPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class ShareModule {}
