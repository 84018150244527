import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EditBlockTimePage } from './edit-block-time';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
    ],
    declarations: [EditBlockTimePage],
    entryComponents: [
        EditBlockTimePage
    ]
})
export class EditBlockTimePageModule {}
